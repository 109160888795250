<template>
  <v-container>
    <v-row justify="center">
      <v-col v-if="!isLoggedIn" md="5">
        <customer-login-tabs></customer-login-tabs>
      </v-col>
      <v-col v-else md="5" class="text-center">
        <div>{{ $t('notification.alreadyLoggedIn') }}</div>
        <base-button
          rounded
          color="pink-berry"
          :to="localePath('/')"
          class="pink-berry white--text my-5"
          >{{ $t('actions.continueShopping') }}</base-button
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useRouter } from '@nuxtjs/composition-api'
import { getApplicationContext, useBreadcrumbs, useUser } from '~/composables'
import CustomerLoginTabs from '~/components/customer/login/CustomerLoginTabs'
import BaseButton from '~/components/base/button/BaseButton'

export default {
  name: 'PageLogin',
  components: {
    BaseButton,
    CustomerLoginTabs,
  },
  setup(_, { root }) {
    const { i18n } = getApplicationContext(root)
    const { setBreadcrumbs } = useBreadcrumbs(root)
    const { onUserLogin, onUserRegister, isLoggedIn } = useUser(root)
    const router = useRouter()

    onUserLogin(() => {
      router.push('/account')
    })
    onUserRegister(() => {
      router.push('/account')
    })

    setBreadcrumbs([
      {
        name: i18n.t('pages.login'),
        path: '/login',
      },
    ])

    return {
      isLoggedIn,
    }
  },
  head() {
    return {
      title: this.$t('pages.login'),
    }
  },
  methods: {
    handleSuccessful() {
      // TODO: handle successful login
    },
  },
}
</script>
