<template>
  <base-tabs v-model="tab" grow>
    <template #tabs>
      <base-tab
        class="text-h4 customer-login-tabs__tab"
        active-class="checkout-primary white--text"
        >{{ $t('actions.newCustomer') }}</base-tab
      >
      <base-tab
        class="text-h4 customer-login-tabs__tab"
        active-class="checkout-primary white--text"
        >{{ $t('actions.signIn') }}</base-tab
      >
    </template>
    <template #items>
      <base-tab-item class="py-5">
        <customer-register-form-container
          :with-guest="withGuestOrder"
          @click:register="$emit('click:register', $event)"
        ></customer-register-form-container>
      </base-tab-item>
      <base-tab-item class="py-5">
        <customer-login-form-container
          @click:paypal="$emit('click:paypal')"
        ></customer-login-form-container>
      </base-tab-item>
    </template>
  </base-tabs>
</template>

<script>
import CustomerRegisterFormContainer from '~/components/customer/login/CustomerRegisterFormContainer'
import CustomerLoginFormContainer from '~/components/customer/login/CustomerLoginFormContainer'
import BaseTabs from '~/components/base/tab/BaseTabs'
import BaseTab from '~/components/base/tab/BaseTab'
import BaseTabItem from '~/components/base/tab/BaseTabItem'

export default {
  name: 'CustomerLoginTabs',
  components: {
    BaseTabItem,
    BaseTab,
    BaseTabs,
    CustomerLoginFormContainer,
    CustomerRegisterFormContainer,
  },
  props: {
    defaultTab: {
      type: Number,
      default: 1,
    },
    withGuestOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: this.defaultTab,
    }
  },
  computed: {
    name() {
      return this.data
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.customer-login-tabs {
  &__tab {
    width: 50%;
    color: map-get($shades, 'checkout-primary');
    caret-color: map-get($shades, 'checkout-primary-pastel');
    background-color: map-get($shades, 'checkout-primary-pastel-light');
    text-transform: uppercase;
  }
}
</style>
