<template>
  <transition :name="transitionName" tag="div">
    <div
      v-show="isActive"
      v-if="isBooted"
      :class="classes"
      class="base-tab-item"
      role="tabpanel"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseTabItem',
  inject: ['tabs'],
  props: {
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBooted: !this.lazy,
    }
  },
  computed: {
    transitionName() {
      return this.tabs.transitionName
    },
    isActive() {
      return this.tabs.isItemActive(this)
    },
    classes() {
      return {
        'base-tab-item--active': this.isActive,
      }
    },
  },
  watch: {
    isActive(val) {
      if (val) {
        this.isBooted = true
      }
    },
  },
  created() {
    this.tabs.registerItem(this)
  },

  beforeDestroy() {
    this.tabs.unregisterItem(this)
  },
}
</script>
