<template>
  <div class="base-tabs" :class="classes">
    <div
      class="base-tabs__wrapper"
      :style="wrapperStyles"
      :class="tabsClass"
      role="tablist"
    >
      <slot name="tabs"></slot>
    </div>
    <div class="base-tabs__items">
      <slot name="items"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',
  provide() {
    return {
      tabs: this,
    }
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    grow: {
      type: Boolean,
      default: false,
    },
    iconsAndText: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: undefined,
    },
    tabsClass: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      internalLazyValue: this.value,
      tabItems: [],
      items: [],
      transitionName: 'base-window-x-reverse-transition',
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.internalLazyValue
      },
      set(val) {
        if (val === this.internalLazyValue) return

        this.internalLazyValue = val

        this.$emit('input', val)
      },
    },
    classes() {
      return {
        'base-tabs--grow': this.grow,
        'base-tabs--icons-and-text': this.iconsAndText,
      }
    },
    wrapperStyles() {
      return {
        height: this.height,
      }
    },
  },
  watch: {
    value(val) {
      this.internalLazyValue = val
    },
  },
  methods: {
    changeTab(tab) {
      const oldTab = this.internalValue
      const newTab = this.tabItems.indexOf(tab)

      this.transitionName =
        oldTab > newTab
          ? 'base-window-x-transition'
          : 'base-window-x-reverse-transition'
      this.internalValue = newTab
    },

    register(item) {
      this.tabItems.push(item)
    },
    unregister(item) {
      if (this._isDestroyed) return

      const index = this.tabItems.indexOf(item)

      this.tabItems.splice(index, 1)
    },
    isItemActive(item) {
      return this.items.indexOf(item) === this.internalValue
    },
    isTabActive(tabItem) {
      return this.tabItems.indexOf(tabItem) === this.internalValue
    },
    registerItem(item) {
      this.items.push(item)
    },
    unregisterItem(item) {
      if (this._isDestroyed) return

      const index = this.items.indexOf(item)

      this.items.splice(index, 1)
    },
  },
}
</script>
