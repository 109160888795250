<template>
  <div role="tab" :class="classes" class="base-tab" @click="changeTab">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseTab',
  inject: ['tabs'],
  props: {
    activeClass: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    isActive() {
      return this.tabs.isTabActive(this)
    },
    classes() {
      return [
        this.isActive ? this.activeClass : '',
        {
          'base-tab--active': this.isActive,
        },
      ]
    },
  },
  created() {
    this.tabs.register(this)
  },

  beforeDestroy() {
    this.tabs.unregister(this)
  },
  methods: {
    changeTab() {
      this.tabs.changeTab(this)
    },
  },
}
</script>
